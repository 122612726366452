import React from 'react'
import {useTranslation} from 'react-i18next'

import {NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Separator from 'src/designSystem/components/atoms/Separator/Separator'
import {CardMinPaymentsQuery} from 'src/designSystem/components/molecules/UpcomingPaymentsCard/CardMinPayments.gqls'
import OverlaySimple from 'src/designSystem/components/organisms/Overlay/variants/OverlaySimple'
import {formatDate, humanReadableDateDayMonth2} from 'src/lib/utils/date'
import {getTestID} from 'src/lib/utils/tests.utils'

interface OverlayDetailsProps {
  visible: boolean
  onDismiss: () => void
  nextPaymentDueDateFiltered: string | undefined
  autopayEnabled: boolean | undefined
  autopayStatus: string
  cardMinPayResponse: CardMinPaymentsQuery['me']['cardAccounts']['active']
}

// eslint-disable-next-line complexity
const ShowDetailsOverlay = ({
  visible,
  onDismiss: handleOnDismiss,
  nextPaymentDueDateFiltered,
  autopayEnabled,
  autopayStatus,
  cardMinPayResponse,
}: OverlayDetailsProps): JSX.Element => {
  const {t} = useTranslation(['UpcomingPaymentsCard'])

  return (
    <OverlaySimple
      testID="ShowDetailsOverlay"
      visible={visible}
      dismissButtonText={t('Close')}
      dismissButtonSize="medium"
      onDismiss={handleOnDismiss}
      padding="medium"
      content={
        <Box>
          {cardMinPayResponse?.balance?.pastDueAfterPayments &&
          cardMinPayResponse?.balance?.pastDueAfterPayments !== '0.00' ? (
            <DetailRow
              label={t('PastDue')}
              value={`$${cardMinPayResponse?.balance?.pastDueAfterPayments}`}
              valueColor={NamedColors.VERMILLION}
            />
          ) : null}
          {autopayEnabled && (
            <>
              <DetailRow
                label={t('AutopayScheduled')}
                value={formatDate(nextPaymentDueDateFiltered, humanReadableDateDayMonth2)}
                description={t('AutopayDateDescription')}
              />

              <DetailRow
                label={t('AutopayAmount')}
                value={
                  autopayStatus === 'Statement Balance'
                    ? `$${cardMinPayResponse?.balance?.statementAfterPayments}`
                    : `$${cardMinPayResponse?.balance?.minimumPaymentDueAfterPayments}`
                }
              />

              <DetailRow label={t('AutopayFrequency')} value={t('Monthly')} />
              <DetailRow label={t('AutopayType')} value={autopayStatus} />
              <Box paddingVertical="medium">
                <Separator />
              </Box>
            </>
          )}
          <DetailRow
            label={t('MinimumDue')}
            value={`$${cardMinPayResponse?.balance?.minimumPaymentDueAfterPayments}`}
            description={
              cardMinPayResponse?.balance?.pastDueAfterPayments &&
              cardMinPayResponse?.balance?.pastDueAfterPayments !== '0.00'
                ? t('MinimumDueDescription', {
                    pastDueAmount: cardMinPayResponse?.balance?.pastDueAfterPayments,
                  })
                : undefined
            }
          />
          <DetailRow
            label={t('StatementBalance')}
            value={`$${cardMinPayResponse?.balance?.statementAfterPayments}`}
            description={t('StatementBalanceDescription')}
          />
          <DetailRow
            label={t('PaymentDueDate')}
            value={formatDate(
              cardMinPayResponse?.statements.mostRecent?.paymentDueDate,
              humanReadableDateDayMonth2,
            )}
            description={t('StatementDueDateDescription')}
          />
        </Box>
      }
    />
  )
}

const DetailRow = ({
  label,
  value,
  description,
  valueColor,
}: {
  label: string
  value: string | number
  description?: string
  valueColor?: NamedColors
}): JSX.Element => (
  <Box paddingVertical="tiny">
    <Box testID={getTestID(label, '-Id')} direction="row">
      <PFText variant="p_semibold">{label}: </PFText>
      <PFText variant="p" color={valueColor}>
        {value}
      </PFText>
    </Box>
    {description ? (
      <PFText variant="p_sm" color={NamedColors.SILVER}>
        {description}
      </PFText>
    ) : null}
  </Box>
)

export {ShowDetailsOverlay}
