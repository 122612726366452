import {FetchResult} from '@apollo/client'
import React, {useCallback, useMemo} from 'react'
import {Controller, FormProvider, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {StyleSheet, View} from 'react-native'

import {NamedColors} from 'src/designSystem/colors'
import PFCheckbox from 'src/designSystem/components/atoms/PFCheckbox/PFCheckbox'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import PFTextInput from 'src/designSystem/components/atoms/PFTextInput'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {ButtonLockupPropsPrimary} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import PFDatePickerField from 'src/designSystem/components/molecules/PFDatePickerField'
import {PFSSNInput} from 'src/designSystem/components/molecules/PFSSNInput/PFSSNInput'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'
import {legalMinAgeForLoan, validBeforeDate} from 'src/lib/time_util'
import {formatDate, graphqlDateFormat} from 'src/lib/utils/date'
import {VerifyYourIdentityFormData} from 'src/products/MCU/VerifyYourIdentity/VerifyYourIdentity.types'
import {
  cleanUpSSN,
  getValidationRules,
} from 'src/products/MCU/VerifyYourIdentity/VerifyYourIdentity.utils'
import {
  UserModifyProfileMutation,
  UserModifyProfileMutationVariables,
} from 'src/products/MCU/VerifyYourIdentity/VerifyYourIdentity.gqls'
import {ShowException} from 'src/lib/errors'
import {useIsFeatureFlagEnabled} from 'src/lib/experimentation/useIsFeatureFlagEnabled'

type VerifyYourIdentityProps = {
  onComplete: () => Promise<void>
  onSubmitUserPersonalInfo: (options: {
    variables: {
      input: UserModifyProfileMutationVariables
    }
  }) => Promise<FetchResult<UserModifyProfileMutation>>
  isSubmittingPersonalInfo: boolean
}

const VerifyYourIdentity: React.FC<VerifyYourIdentityProps> = (props) => {
  const {onComplete, onSubmitUserPersonalInfo, isSubmittingPersonalInfo} = props
  const hasAppRevampUiUpdates = useIsFeatureFlagEnabled('app-revamp-ui-updates')
  const {t} = useTranslation(['Common', 'VerifyYourIdentity'])
  const birthdayLabel = 'Common:Birthday'

  const formMethods = useForm<VerifyYourIdentityFormData>({
    mode: 'all',
    defaultValues: {
      firstName: '',
      lastName: '',
      birthday: '',
      ssn: '',
      didCertifySSN: false,
    },
  })
  const {isValid, isDirty} = formMethods.formState

  const rules = useMemo(() => getValidationRules(t), [t])

  const handleOnSubmit = useCallback(
    async (data: VerifyYourIdentityFormData): Promise<void> => {
      const input: UserModifyProfileMutationVariables = {}

      try {
        if (data.firstName && data.lastName) {
          input.name = {
            first: data.firstName,
            last: data.lastName,
          }
        }

        if (data.birthday) {
          input.dob = {dob: formatDate(data.birthday, graphqlDateFormat)}
        }

        if (data.ssn) {
          input.ssn = {ssn: cleanUpSSN(data.ssn)}
        }

        const resp = await onSubmitUserPersonalInfo({variables: {input}})

        if (resp.errors) {
          throw new Error(resp.errors[0].message)
        }

        await onComplete()
      } catch (e) {
        ShowException(e)
      }
    },
    [onComplete, onSubmitUserPersonalInfo],
  )

  const primaryAction: ButtonLockupPropsPrimary = useMemo(
    () => ({
      testID: 'Continue-Button',
      text: t('Common:Continue'),
      onPress: formMethods.handleSubmit(handleOnSubmit),
      disabled: !isDirty || !isValid || isSubmittingPersonalInfo,
    }),
    [formMethods, handleOnSubmit, isDirty, isSubmittingPersonalInfo, isValid, t],
  )

  const description = useMemo(
    () => (
      <View style={styles.description}>
        <View style={styles.icon}>
          <SvgIcon colorVariant="active" name="info" size="large" />
        </View>
        <PFText variant="p" textProps={{style: styles.flex}}>
          {t('VerifyYourIdentity:Description')}
        </PFText>
      </View>
    ),
    [t],
  )

  return (
    <FormProvider {...formMethods}>
      <Page
        variant={'generic'}
        smallTopGap={true}
        buttonProps={buttonLockupProperties(primaryAction)}
        title={t('VerifyYourIdentity:Title')}
        description={description}
        enabledKeyboardAvoidingView
        testID="Verify-Your-Identity-Page"
      >
        <View style={styles.fieldContainer}>
          <PFText variant="p_semibold">{t('Common:Name')}</PFText>
          <Controller
            name="firstName"
            rules={rules.firstName}
            control={formMethods.control}
            render={({onChange: handleOnChange, value}): JSX.Element => (
              <PFTextInput
                testID="First-Name-Input"
                error={formMethods.errors['firstName']?.message}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                value={value}
                onChangeText={handleOnChange}
                label="First Name"
              />
            )}
          />
          <Controller
            name="lastName"
            rules={rules.lastName}
            control={formMethods.control}
            render={({onChange: handleOnChange, value}): JSX.Element => (
              <PFTextInput
                testID="Last-Name-Input"
                error={formMethods.errors['lastName']?.message}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                value={value}
                onChangeText={handleOnChange}
                label={t('Common:LastName')}
              />
            )}
          />
        </View>

        <View style={styles.fieldContainer}>
          <PFText variant="p_semibold">{t(birthdayLabel)}</PFText>
          <Controller
            control={formMethods.control}
            name="birthday"
            render={({onChange: handleOnChange, value}): JSX.Element => (
              <PFDatePickerField
                onChange={handleOnChange}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                value={value}
                label={t(birthdayLabel)}
                format="MM/DD/YYYY"
                minimumDate={validBeforeDate.toDate()}
                maximumDate={legalMinAgeForLoan.toDate()}
              />
            )}
          />
        </View>

        <View style={styles.fieldContainer}>
          <PFText variant="p_semibold">{t('Common:SocialSecurityNumber')}</PFText>
          <Controller
            name="ssn"
            rules={rules.ssn}
            control={formMethods.control}
            render={({onChange: handleOnChange, value}): JSX.Element => (
              <PFSSNInput
                testID="SSN-Input"
                error={formMethods.errors['ssn']?.message}
                onChangeText={handleOnChange}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                value={value}
              />
            )}
          />
        </View>

        {hasAppRevampUiUpdates ? null : (
          <View style={styles.fieldContainer} testID="Ssn-Disclosure">
            <PFText variant="p" textProps={{style: styles.info}}>
              {t('VerifyYourIdentity:SsnDisclosure')}
            </PFText>
          </View>
        )}

        <View style={styles.fieldContainer}>
          <Controller
            name="didCertifySSN"
            rules={rules.didCertifySSN}
            control={formMethods.control}
            render={({onChange: handleOnChange, value}): JSX.Element => (
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              <PFCheckbox onPress={handleOnChange} checked={value} testID={'Certify-SSN-Checkbox'}>
                <PFText variant="p">{t('VerifyYourIdentity:ICertifyThatMySSNIsCorrect')}</PFText>
              </PFCheckbox>
            )}
          />
        </View>
      </Page>
    </FormProvider>
  )
}

export {VerifyYourIdentity}

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  description: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    columnGap: 12,
  },
  icon: {
    padding: 6,
  },

  fieldContainer: {
    rowGap: 12,
    marginBottom: 24,
  },
  info: {
    color: NamedColors.SILVER,
  },
})
