import React, {FC, forwardRef, RefObject, useEffect, useMemo, useRef, useState} from 'react'
import {Animated, TextInput, TextInputProps, TouchableWithoutFeedback, View} from 'react-native'

import {formFieldProps} from 'src/designSystem/components/atoms/HookForm/HookForm'
import PFFieldBase from 'src/designSystem/components/atoms/PFFieldBase/PFFieldBase'
import {
  PFFieldBaseStates,
  PFFieldBaseStateTypes,
  textButtonProps,
} from 'src/designSystem/components/atoms/PFFieldBase/types'
import Input from 'src/designSystem/components/atoms/PFTextInput/Input'
import styles from 'src/designSystem/components/atoms/PFTextInput/styles'
import {SvgIconProps} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {isStringValueEmpty} from 'src/designSystem/lib/dataUtil'
import {getBaseFieldState, getErrorState} from 'src/designSystem/lib/fieldUtil'
import {isDeviceWeb} from 'src/lib/utils/platform'

export type AutoCapitalizeProps = 'none' | 'sentences' | 'words' | 'characters'

export type PFTextInputProps = TextInputProps & {
  label: string
  value?: string
  onPress?: () => void
  onPressIcon?: () => void
  onFocus?: () => void
  onBlur?: () => void
  changeFilter?: (value: string) => string
  onChangeText?: (text: string) => void
  error?: string
  infoMessage?: string
  icon?: SvgIconProps
  errorOnEmpty?: boolean
  editable?: boolean
  autoCorrect?: boolean
  autoCapitalize?: AutoCapitalizeProps
  multiline?: boolean
  numberOfLines?: number
  formProps?: formFieldProps
  onSubmitEditing?: () => void
  returnKeyType?: string
  keyboardType?: string
  maxLength?: number
  secureTextEntry?: boolean
  autoCompleteType?: string
  textContentType?: string
  testID?: string
  textButton?: textButtonProps
  ref?: React.Ref<TextInput>
  editableStyle?: boolean
  iconTestID?: string
}

const PFTextInput: FC<PFTextInputProps> = forwardRef(
  (props: PFTextInputProps, ref: React.ForwardedRef<TextInput>) => {
    const {
      label,
      value,
      multiline: isMultiline,
      numberOfLines,
      changeFilter,
      onPress,
      onFocus,
      onBlur,
      onPressIcon: handlePressIcon,
      error,
      errorOnEmpty: shouldErrorOnEmpty,
      infoMessage,
      editable: isEditable,
      icon,
      onChangeText,
      textButton,
      testID,
      editableStyle: hasEditableStyle,
      iconTestID,
      ...otherProps
    } = props

    const inputEl: RefObject<TextInput> = useRef(null)
    useEffect(() => {
      if (!ref) {
        return
      } else if (typeof ref === 'function') {
        ref(inputEl.current)
      } else {
        ref.current = inputEl.current
      }
    })

    const [isFocused, setIsFocused] = useState(false)
    const [isFirstAttempt, setIsFirstAttempt] = useState(true)
    const [textInputState, setTextInputState] = useState<PFFieldBaseStateTypes>(
      PFFieldBaseStates.EMPTY,
    )
    const isValueEmpty: boolean = isStringValueEmpty(value)
    const hasIcon = !!icon
    const animationControl: Animated.Value = useMemo(
      () => new Animated.Value(isValueEmpty ? 0 : 1),
      [isValueEmpty],
    )

    useEffect(() => {
      const hasErrorState = getErrorState(shouldErrorOnEmpty, isValueEmpty, isFirstAttempt, error)
      const newState = getBaseFieldState(hasErrorState, isValueEmpty, isFocused)
      setTextInputState(newState)
    }, [value, isFocused, isValueEmpty, shouldErrorOnEmpty, error, isFirstAttempt])

    const renderOverrideView = (onPressOverride: () => void): React.ReactNode => {
      if (isDeviceWeb() && isMultiline) {
        //disabling to allow textarea resize on web
        return
      }

      const handlePressFieldWrapper = (): void => {
        inputEl?.current?.focus()
        onPressOverride()
      }

      return (
        <TouchableWithoutFeedback onPress={handlePressFieldWrapper}>
          <View style={styles.overrideView} />
        </TouchableWithoutFeedback>
      )
    }

    const handleBlur = (): void => {
      setIsFocused(false)
      setIsFirstAttempt(false)

      onBlur?.()
    }

    const handleFocus = (): void => {
      setIsFocused(true)
      onFocus?.()
    }

    const handleChangeText = (newValue: string): void => {
      if (changeFilter) {
        newValue = changeFilter(newValue)
      }
      onChangeText?.(newValue)
    }

    return (
      <PFFieldBase
        label={label}
        animationControl={animationControl}
        infoMessage={infoMessage}
        error={error}
        editable={isEditable || hasEditableStyle}
        fieldState={textInputState}
        icon={icon}
        onPressIcon={handlePressIcon}
        textButton={textButton}
        placeholder={otherProps.placeholder}
        testID={iconTestID}
      >
        <Input
          ref={inputEl}
          value={value}
          multiline={isMultiline}
          hasIcon={hasIcon}
          editable={isEditable}
          numberOfLines={numberOfLines}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChangeText={handleChangeText}
          accessible={true}
          testID={testID}
          {...otherProps}
        />
        {onPress ? renderOverrideView(onPress) : null}
      </PFFieldBase>
    )
  },
)

PFTextInput.displayName = 'PFTextInput'

PFTextInput.defaultProps = {
  editable: true,
  multiline: false,
  numberOfLines: 1,
}

export default PFTextInput
