/* eslint-disable deprecate/import */
/* eslint-disable no-type-assertion/no-type-assertion */
/* eslint-disable import/group-exports */

/* 
These imports have been separated by function/product and 
alphabetized to make it easier to read and manage
*/

// EXTERNAL IMPORTS
import React, {useEffect} from 'react'
import {AppState, AppStateStatus} from 'react-native'
import {ParamListBase, RouteProp} from '@react-navigation/native'
import {StackNavigationOptions, StackNavigationProp} from '@react-navigation/stack'

// POSSIBLE FINANCE IMPORTS
import {GetMeAction} from '@possible/cassandra'

// MISC IMPORTS SUPPORTING NAVIGATION
import {isDeviceNotWeb, isDeviceWeb} from 'src/lib/utils/platform'
import {MainStackNav, globalCardStyle, headerButtonsWhiteColor} from 'src/nav/Stacks/consts'
import {MainStackPages, MainStackParamList} from 'src/nav/MainStackParamsList'
import {onboardingSentToWeb} from 'src/lib/onboarding/slice'
import {sentToWebSelector} from 'src/lib/onboarding/selectors'
import {useExperimentationWrapper} from 'src/lib/experimentation/useExperimentationWrapper'
import {usePfDispatch, usePfSelector} from 'src/store/utils'
import {userMeSelector} from 'src/lib/user/selector'
import {useTempTokenAuthentication} from 'src/lib/user/useTempTokenAuthentication'
import {UseUra} from 'src/nav/UseUra'
import {useURLNavigation} from 'src/nav/useURLNavigation'
import {useWorkflows} from 'src/workflows/hooks/useWorkflows'
import {useWorkflowsInitialRoute} from 'src/workflows/hooks/useWorkflowsInitialRoute'
import AppInactiveContentHider from 'src/nav/Stacks/AppInactiveContentHider'
import Init from 'src/nav/Stacks/Init'
import Log from 'src/lib/loggingUtil'
import Onboarding from 'src/flows/onboarding'

// DESIGN SYSTEM IMPORTS
import {genericPageBackground, primaryBackgroundColor} from 'src/designSystem/semanticColors'
import {NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import GenericNonModalTemplate from 'src/designSystem/components/templates/GenericNonModalTemplate/GenericNonModalTemplate'
import LightBox from 'src/designSystem/components/organisms/Lightbox'
import Loading from 'src/designSystem/components/atoms/Loading/Loading'

// PRODUCTS/GENERAL IMPORTS
import {ApplicationActivationWorkflow} from 'src/products/general/ApplicationActivationWorkflow/ApplicationActivationWorkflow'
import {JointAccountVerification} from 'src/products/general/UserRequestedActions/JointAccountVerification/JointAccountVerification'
import {LivenessRouteContainer} from 'src/products/general/Liveness/LivenessRouteContainer'
import {OfferApplicationWorkflow} from 'src/products/general/OfferApplicationWorkflow/OfferApplicationWorkflow'
import {ProductHub} from 'src/products/general/ProductHub/ProductHub'
import {ProofOfIncome} from 'src/products/general/UserRequestedActions/ProofOfIncome/ProofOfIncome'
import {shouldShowAddBankLinkPageWithBanner} from 'src/products/general/AddBankLink/AddBankLinkTemplate'
import AddBankLink from 'src/products/general/AddBankLink/AddBankLinkLegacyContainer'
import LeftDrawer from 'src/products/general/LeftDrawer/LeftDrawer'
import MarketingSurvey from 'src/products/general/MarketingSurvey/MarketingSurveyLegacyContainer'
import PDFViewer from 'src/products/general/PDFViewer/PDFViewer'
import ScreenSelectorModuleContainer from 'src/products/general/VersionFooter/AppScreenSelector/ScreenSelectorModuleContainer'
import {SignupWorkflow} from 'src/products/general/SignupWorkflow/SignupWorkflow'

// PRODUCTS/LOANS IMPORTS
import {AcceptACH} from 'src/products/loans/LoanApprovedActivation/AcceptAchAndAgreements/AcceptACH/AcceptACH'
import {AcceptAgreementsWAInstallment} from 'src/products/loans/LoanApprovedActivation/AcceptAchAndAgreements/AcceptAgreements/WAInstallment/AcceptAgreementsWAInstallment'
import {CancelSurveyNavPageStateContainer} from 'src/products/loans/LoanApprovedActivation/CancelSurvey/CancelSurveyNavPageStateContainer'
import {CardPaymentDetails} from 'src/products/loans/ProductChoice/CardPaymentDetails'
import {ConfirmBankAccountNavPageStateContainer} from 'src/products/loans/LoanApprovedActivation/ConfirmBankAccount/ConfirmBankAccountNavPageStateContainer'
import {ConfirmDebitCardNavPageStateContainer} from 'src/products/loans/LoanApprovedActivation/ConfirmDebitCard/ConfirmDebitCardNavPageStateContainer'
import {DashboardLoader} from 'src/products/loans/DashboardLoader/DashboardLoader'
import {DashboardLoanContainer} from 'src/products/loans/Dashboard/DashboardLoan/DashboardLoanContainer'
import {EmailVerificationRouteContainer} from 'src/products/loans/EmailVerification/EmailVerificationRouteContainer'
import {HowPaymentsWorkNavPageStateContainer} from 'src/products/loans/LoanApprovedActivation/HowPaymentsWork/HowPaymentsWorkNavPageStateContainer'
import {IDScanRouteContainer} from 'src/products/loans/IDScan/IDScanRouteContainer'
import {Landing} from 'src/products/loans/Landing/Landing'
import {LoanPaymentReviewNavPageStateContainer} from 'src/products/loans/LoanApprovedActivation/LoanPaymentReview/LoanPaymentReviewNavPageStateContainer'
import {LoanAmountSelectionLegacyContainer} from 'src/products/loans/LoanSelection/LoanAmountSelection/LoanAmountSelectionLegacyContainer'
import {LoanDisbursementMethodSelectionNavPageStateContainer} from 'src/products/loans/LoanApprovedActivation/LoanDisbursementMethodSelection/LoanDisbursementMethodSelectionNavPageStateContainer'
import {LoanFinalAcceptNavPageStateContainer} from 'src/products/loans/LoanApprovedActivation/LoanFinalAccept/LoanFinalAcceptNavPageStateContainer'
import {LoanPayNavigator} from 'src/products/loans/AdhocPayment/LoanPayNavigator'
import {LoanProcessingCounterOfferNavPageStateContainer} from 'src/products/loans/LoanProcessing/LoanProcessingCounterOffer/LoanProcessingCounterOfferNavPageStateContainer'
import {LoanReapplicationSubmissionNavPageStateContainer} from 'src/products/loans/LoanApplicationSubmission/LoanReapplicationSubmission/LoanReapplicationSubmissionNavPageStateContainer'
import {LoanTilaDisclosureNavPageStateContainer} from 'src/products/loans/LoanApprovedActivation/LoanTilaDisclosure/LoanTilaDisclosureNavPageStateContainer'
import {MoneyOnTheWayNavigationContainer} from 'src/products/loans/MoneyOnTheWay/MoneyOnTheWayNavigationContainer'
import {PaymentReviewTilaAndLoanAgreementCANavPageStateContainer} from 'src/products/loans/LoanApprovedActivation/PaymentReviewTilaAndLoanAgreementCA/PaymentReviewTilaAndLoanAgreementCANavPageStateContainer'
import {ReasonSurveyNavPageStateContainer} from 'src/products/loans/LoanApprovedActivation/ReasonSurvey/ReasonSurveyNavPageStateContainer'
import {UnsupportedStateWaitList} from 'src/products/loans/StateSelector/UnsupportedStateWaitList'
import {UpgradeToInstallmentPlanNavPageStateContainer} from 'src/products/loans/UpgradeToInstallmentPlan/UpgradeToInstallmentPlanNavPageStateContainer'
import AcceptAgreements from 'src/products/loans/LoanApprovedActivation/AcceptAchAndAgreements/AcceptAgreements/AcceptAgreements'
import DocumentsHistory from 'src/products/loans/DocumentsHistory/DocumentHistory'
import DuplicatePhoneNumber from 'src/products/loans/PhoneConfirmation/DuplicatePhoneNumber'
import EditEmail from 'src/products/loans/EmailPasswordManagement/EditEmail'
import LoanHistory from 'src/products/loans/LoanHistory/DocumentHistory'
import NameMatchesIDConfirm from 'src/products/loans/PersonalInformation/NameMatchesIDConfirm'
import OTPIdentityVerification from 'src/products/loans/IdentityVerification/OTPIdentityVerification'
import OutOfState from 'src/products/loans/PersonalInformation/OutOfState'
import PaymentRescheduleCalendar from 'src/products/loans/Reschedule/PaymentRescheduleCalendar'
import PersonalInformation from 'src/products/loans/PersonalInformation/PersonalInformationLegacyContainer'
import PhoneConfirmation from 'src/products/loans/PhoneConfirmation/PhoneConfirmationLegacyContainer'
import PhoneConfirmationApproval from 'src/products/loans/PhoneConfirmation/PhoneConfirmationApproval'
import PhoneVerification from 'src/products/loans/PhoneConfirmation/PhoneVerificationLegacyContainer'
import {ZendeskHelpCenter} from 'src/products/general/ZendeskHelpCenter/ZendeskHelpCenter'
import ProofOfResidence from 'src/products/loans/PersonalInformation/ProofOfResidence'
import ProofOfResidenceConfirm from 'src/products/loans/PersonalInformation/ProofOfResidenceConfirm'
import RetakeFrontDL from 'src/products/general/UserRequestedActions/RetakeFrontDL'
import RetakeLiveness from 'src/products/general/UserRequestedActions/RetakeLiveness'
import SetSchedule from 'src/products/loans/Reschedule/SetSchedule'
import SettlementOnboarding from 'src/products/loans/Settlements/SettlementOnboarding'
import SettlementReview from 'src/products/loans/Settlements/SettlementReview'
import SSNCard from 'src/products/general/UserRequestedActions/SSNCard'
import SSNCollectionRouteContainer from 'src/products/loans/PersonalInformation/SSNCollection/SSNCollectionRouteContainer'

// PRODUCTS/CARD IMPORTS
import {CardActivationAutopayScheduleMPOContainer} from 'src/products/card/Activation/CardActivationAutopaySchedule/CardActivationAutopayScheduleMPOContainer'
import {CardAdhocPayment} from 'src/products/card/AdhocPayment/CardAdhocPayment'
import {CardAgreementViewerMPOContainer} from 'src/products/card/Application/CardAgreementViewer/CardAgreementViewerMPOContainer'
import {CardApplicationExpired} from 'src/products/card/Application/CardApplicationExpired/CardApplicationExpired'
import {CardApplicationRejectedContainer} from 'src/products/card/Application/CardApplicationRejected/CardApplicationRejectedContainer'
import {CardApprovedMPOContainer} from 'src/products/card/Activation/CardApproved/CardApprovedMPOContainer'
import {CardAutopayOverviewMPOContainer} from 'src/products/card/Activation/CardAutopayOverview/CardAutopayOverviewMPOContainer'
import {CardHowPayOverTimeWorks} from 'src/products/card/PayOverTime/CardHowPayOverTimeWorks'
import {CardPayOverTimeDetailsContainer} from 'src/products/card/PayOverTime/CardPayOverTimeDetails/CardPayOverTimeDetailsContainer'
import {CardPayOverTimeScheduleContainer} from 'src/products/card/PayOverTime/CardPayOverTimeSchedule/CardPayOverTimeScheduleContainer'
import {CardRescheduleUpcomingPayment} from 'src/products/card/Dashboard/CardRescheduleUpcomingPayment/CardRescheduleUpcomingPayment'
import {CardReviewAutopay} from 'src/products/card/Autopay/CardReviewAutopay/CardReviewAutopay'
import {PayOverTimeFAQ} from 'src/products/card/PayOverTime/PayOverTimeFAQ/PayOverTimeFAQ'
import {useEnrollInCardsIfNecessary} from 'src/products/card/hooks/useEnrollInCardsIfNecessary'
import CardActivatePhysicalCard from 'src/products/card/PhysicalCardActivation/CardActivatePhysicalCard'
import CardActivatingPhysicalCard from 'src/products/card/PhysicalCardActivation/CardActivatingPhysicalCard'
import CardDashboard from 'src/products/card/Dashboard/CardDashboard'
import {CardDetails} from 'src/products/card/CardDetails/CardDetails'
import CardHowDelinquencyAffectMe from 'src/products/card/Dashboard/CardHowDelinquencyAffectMe'
import CardHowPayOverTimeWorksManual from 'src/products/card/PayOverTime/CardHowPayOverTimeWorksManual'
import CardLanding from 'src/products/card/CardLanding/CardLanding'
import {CardLandingWebV2} from 'src/products/card/CardLandingWeb/CardLandingWebV2'
import CardMakePaymentPOT from 'src/products/card/Dashboard/CardMakePaymentPOT'
import CardPhysicalCardActivated from 'src/products/card/PhysicalCardActivation/CardPhysicalCardActivated'
import CardPhysicalCardActivationFailed from 'src/products/card/PhysicalCardActivation/CardPhysicalCardActivationFailed'
import CardsHistory from 'src/products/card/CardsHistory/CardsHistory'
import CardSignedUpAutopay from 'src/products/card/Autopay/CardSignedUpAutopay'
import CardSubmitApplication from 'src/products/card/Application/CardSubmitApplication'
import CardTransactions from 'src/products/card/Dashboard/CardTransactions'
import CardVerifyBankDetails from 'src/products/card/PaymentMethods/CardVerifyBankDetails'
import CardViewPaymentMethods from 'src/products/card/PaymentMethods/CardViewPaymentMethods/CardViewPaymentMethods'
import HowDoesAutopayWork from 'src/products/card/Autopay/HowDoesAutopayWork'
import PayOverTimeConfirmation from 'src/products/card/PayOverTime/PayOverTimeConfirmation'

// PRODUCTS/MCU IMPORTS
import {AcceptLoanAutoPayAgreement} from 'src/products/MCU/AccountManagementV2/AcceptLoanAutoPayAgreement/AcceptLoanAutoPayAgreement'
import {AcceptPrimaryAccountAgreement} from 'src/products/MCU/AccountManagementV2/AcceptPrimaryAccountAgreement/AcceptPrimaryAccountAgreement'
import {AccountManagementV2} from 'src/products/MCU/AccountManagementV2/AccountManagementV2'
import {AccountRecoveryInstructions} from 'src/products/MCU/AccountRecovery/AccountRecoveryInstructions'
import {AccountsWithPossible} from 'src/products/MCU/AccountManagementV2/AccountsWithPossible/AccountsWithPossible'
import {AddNewAccountToPossible} from 'src/products/MCU/AccountManagementV2/AddNewAccount/AddNewAccountToPossible'
import {AddPrimaryAccount} from 'src/products/MCU/AccountManagementV2/PrimaryAccount/AddPrimaryAccount'
import {AddressLegacyContainer} from 'src/products/MCU/Address/AddressLegacyContainer'
import {AddressUpdate} from 'src/products/MCU/Address/AddressUpdate'
import {AggregatorPlaidFlagRouter} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/AggregatorPlaidFlagRouter'
import {BankAddRoutingAndAccount} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAccount/BankAddRoutingAndAccount/BankAddRoutingAndAccount'
import {BankAggregatorAccountSelectionForAdhocPayment} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/BankAggregatorAccountSelectionForAdhocPayment/BankAggregatorAccountSelectionForAdhocPayment'
import {BankAggregatorAccountSelectionForPrimaryAccount} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/BankAggregatorAccountSelectionForPrimaryAccount/BankAggregatorAccountSelectionForPrimaryAccount'
import {BankVerifyRoutingAndAccount} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/BankVerifyRoutingAndAccount/BankVerifyRoutingAndAccount'
import {CollectDebitCardNumberForAdhocPayment} from 'src/products/MCU/AccountManagementV2/PaymentMethods/DebitCard/CollectDebitCardNumberForAdhocPayment'
import {CollectDebitCardNumbersForCardProduct} from 'src/products/MCU/AccountManagementV2/PaymentMethods/DebitCard/CollectDebitCardNumbersForCardProduct/CollectDebitCardNumbersForCardProduct'
import {CollectDebitCardNumbersForLoanNavPageStateContainer} from 'src/products/MCU/AccountManagementV2/PaymentMethods/DebitCard/CollectDebitCardNumbersForLoan/CollectDebitCardNumbersForLoanNavPageStateContainer'
import {Login} from 'src/products/MCU/RegistrationOrLogin/Login/Login'
import {PhoneNumberInputVerificationForgotPasswordContainer} from 'src/products/MCU/PhoneNumberInputVerification/PhoneNumberInputVerificationForgotPasswordContainer'
import {PrimaryAccountSelectionRouteContainer} from 'src/products/MCU/AccountManagementV2/PrimaryAccountSelection/PrimaryAccountSelectionRouteContainer'
import {ReapplicationBankNavPageStateContainer} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/ReapplicationBank/ReapplicationBankNavPageStateContainer'
import {Registration} from 'src/products/MCU/RegistrationOrLogin/Registration/Registration'
import {SetAccountAsPrimary} from 'src/products/MCU/AccountManagementV2/PrimaryAccount/SetAccountAsPrimary'
import {SetLoanAutoPayAccount} from 'src/products/MCU/AccountManagementV2/SetLoanAutoPayAccount/SetLoanAutoPayAccount'
import {VerifyEmailLinkContainer} from 'src/products/MCU/VerifyEmailLink/VerifyEmailLinkContainer'
import AccountRecovery from 'src/products/MCU/AccountRecovery/AccountRecovery'
import AggregatorYodlee from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/AggregatorYodlee'
import CreatePasswordLoggedOut from 'src/products/MCU/LoginTransition/CreatePasswordLoggedOut'
import EditPassword from 'src/products/MCU/RegistrationOrLogin/EditPassword'
import OTPVerification from 'src/products/MCU/RegistrationOrLogin/OTPVerification'
import Profile from 'src/products/MCU/Profile/Profile'
import TemporaryPassword from 'src/products/MCU/LoginTransition/TemporaryPassword'
import UnsupportedBanks from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/UnsupportedBanks/UnsupportedBanks'
import {StateDisclosureNavPageStateContainer} from 'src/products/loans/StateDisclosure/StateDisclosureNavPageStateContainer'
import {useCanAccessCards} from 'src/products/card/LoanDash/useCanAccessCards'
import {useIsFeatureFlagEnabled} from 'src/lib/experimentation/useIsFeatureFlagEnabled'
import PossibleWebView from 'src/products/loans/PossibleWebView/PossibleWebView'
import {ZendeskSSOForm} from 'src/products/general/ZendeskHelpCenter/ZendeskSSOForm'

const initialAuthenticatedRoute: MainStackPages = 'Init'

let wasLastRouteDashboardLoader = false //This is to prevent an initial animation after we load the users state

type RoutesProps = {
  StackNav: typeof MainStackNav
  baseOptions:
    | StackNavigationOptions
    | ((props: {
        route: RouteProp<ParamListBase>
        navigation: StackNavigationProp<ParamListBase>
      }) => StackNavigationOptions)
}

export const UnauthenticatedRoutes: React.FC<RoutesProps> = (props: RoutesProps) => {
  useExperimentationWrapper(false)
  const {StackNav, baseOptions} = props

  const isCardUser = useCanAccessCards().canAccessCards

  // This feature flag is filtered in Amplitude to be android specific.
  const isAndroidSkipAppLandingEnabled = useIsFeatureFlagEnabled('android-skip-app-landing')

  useTempTokenAuthentication()

  useURLNavigation()

  const getInitialRouteName = (): keyof MainStackParamList => {
    let initialRouteName: keyof MainStackParamList = 'Landing'

    if (isDeviceWeb() || isAndroidSkipAppLandingEnabled) {
      initialRouteName = 'Registration'
    }

    return initialRouteName
  }

  return (
    <StackNav.Navigator screenOptions={baseOptions} initialRouteName={getInitialRouteName()}>
      <StackNav.Screen
        name="VerifyEmailLink"
        component={VerifyEmailLinkContainer}
        options={{
          headerShown: false,
        }}
      />
      <StackNav.Screen
        name="Landing"
        component={Landing}
        options={{
          cardShadowEnabled: false,
          cardStyle: isDeviceWeb() ? {flex: 1, backgroundColor: genericPageBackground} : undefined,
          headerShown: false,
        }}
      />
      <StackNav.Screen
        name="CardLandingWebV2"
        component={CardLandingWebV2}
        options={{
          headerShown: isDeviceNotWeb(),
          ...(headerButtonsWhiteColor as StackNavigationOptions),
        }}
      />
      <StackNav.Screen
        name="Login"
        component={Login}
        options={{
          headerShown: isDeviceNotWeb(),
          headerStyle: {
            headerButtonsColor: NamedColors.WHITE,
          } as StackNavigationOptions['headerStyle'],
        }}
      />
      <StackNav.Screen
        name="Registration"
        component={Registration}
        options={{
          headerShown: isDeviceNotWeb(),
          headerStyle: {
            headerButtonsColor: NamedColors.WHITE,
          } as StackNavigationOptions['headerStyle'],
        }}
      />
      <StackNav.Screen name="CreatePasswordLoggedOut" component={CreatePasswordLoggedOut} />
      <StackNav.Screen name="AccountRecovery" component={AccountRecovery} />
      <StackNav.Screen name="AccountRecoveryInstructions" component={AccountRecoveryInstructions} />
      {!isCardUser ? (
        <StackNav.Screen
          name="PhoneConfirmation"
          component={PhoneNumberInputVerificationForgotPasswordContainer}
        />
      ) : (
        <>
          <StackNav.Screen name="PhoneConfirmation" component={PhoneConfirmation} />
          <StackNav.Screen name="PhoneVerification" component={PhoneVerification} />
        </>
      )}
      <StackNav.Screen name="OTPVerification" component={OTPVerification} />
      <StackNav.Screen name="TemporaryPassword" component={TemporaryPassword} />
      <StackNav.Screen name="ZendeskHelpCenter" component={ZendeskHelpCenter} />
      <StackNav.Screen name="PossibleWebView" component={PossibleWebView} />
    </StackNav.Navigator>
  )
}

/**
 * UPGRADE NOTE AND TODO (https://possible.atlassian.net/browse/ENG-16994):
 * Any screen here that is cast as `as unknown as React.FC` is a screen that is not yet migrated to the new
 * react-navigation types. This is a temporary solution until we migrate all screens to the new types.
 * The new types DO NOT allow custom props -- so `withForwardedNavigationProps` will not work.
 */
export const MainAndModalRoutes: React.FC<RoutesProps & {initialRouteName?: MainStackPages}> = (
  props,
) => {
  const {StackNav, baseOptions, initialRouteName} = props

  return (
    <StackNav.Navigator
      screenOptions={(optionsProps): StackNavigationOptions => {
        const {route} = optionsProps

        const isAnimationEnabled = !wasLastRouteDashboardLoader && route.name !== 'DashboardLoader'
        wasLastRouteDashboardLoader = route.name === 'DashboardLoader'

        return {
          ...(typeof baseOptions === 'function' ? baseOptions(optionsProps) : baseOptions),
          animationEnabled: isAnimationEnabled,
        }
      }}
      initialRouteName={initialRouteName}
    >
      <StackNav.Screen name="Init" component={Init} />
      <StackNav.Screen name="DashboardLoader" component={DashboardLoader} />
      <StackNav.Screen name="AccountRecoveryAuthenticated" component={AccountRecovery} />
      <StackNav.Screen
        name="AccountRecoveryInstructionsAuthenticated"
        component={AccountRecoveryInstructions}
      />
      <StackNav.Screen name="EditPassword" component={EditPassword} />
      <StackNav.Screen name="EditEmail" component={EditEmail} />
      <StackNav.Screen name="ZendeskHelpCenter" component={ZendeskHelpCenter} />
      <StackNav.Screen name="PossibleWebView" component={PossibleWebView} />
      <StackNav.Screen name="LoanAmountSelection" component={LoanAmountSelectionLegacyContainer} />
      <StackNav.Screen
        name="AggregatorPlaid"
        component={AggregatorPlaidFlagRouter as unknown as React.FC}
        options={{cardStyle: {backgroundColor: genericPageBackground}, headerShown: false}}
      />
      <StackNav.Screen name="LoansMarketingSurvey" component={MarketingSurvey} />
      <StackNav.Screen name="Liveness" component={LivenessRouteContainer} />
      <StackNav.Screen name="IDScan" component={IDScanRouteContainer} />
      <StackNav.Screen name="PersonalInformation" component={PersonalInformation} />
      <StackNav.Screen name="AddressCollection" component={AddressLegacyContainer} />
      <StackNav.Screen name="AddressUpdate" component={AddressUpdate} />
      <StackNav.Screen name="SsnConfirm" component={SSNCollectionRouteContainer} />
      <StackNav.Screen name="SSNCard" component={SSNCard} />
      <StackNav.Screen
        name="LightBox"
        component={LightBox as unknown as React.FC}
        options={{
          cardOverlayEnabled: true,
          cardStyle: {backgroundColor: 'transparent'},
          headerShown: false,
        }}
      />

      <StackNav.Screen name="PhoneConfirmation" component={PhoneConfirmation} />
      <StackNav.Screen name="PhoneVerification" component={PhoneVerification} />

      <StackNav.Screen
        name="LoanSubmission"
        component={LoanReapplicationSubmissionNavPageStateContainer}
      />
      <StackNav.Screen name="CancelSurvey" component={CancelSurveyNavPageStateContainer} />
      <StackNav.Screen
        name="Dashboard"
        component={DashboardLoanContainer}
        options={{cardStyle: {...globalCardStyle, backgroundColor: primaryBackgroundColor}}}
      />
      <StackNav.Screen
        name="LoanCounterOffer"
        component={LoanProcessingCounterOfferNavPageStateContainer}
      />
      <StackNav.Screen
        name="LoanPaymentReview"
        component={LoanPaymentReviewNavPageStateContainer}
      />
      <StackNav.Screen name="AcceptACH" component={AcceptACH} />
      <StackNav.Screen name="AcceptAgreements" component={AcceptAgreements} />
      <StackNav.Screen
        name="AcceptPrimaryAccountAgreement"
        component={AcceptPrimaryAccountAgreement}
      />
      <StackNav.Screen name="AcceptLoanAutoPayAgreement" component={AcceptLoanAutoPayAgreement} />

      <StackNav.Screen
        name="ConfirmBankAccount"
        component={ConfirmBankAccountNavPageStateContainer}
      />
      <StackNav.Screen
        name="LoanFinalAccept"
        component={LoanFinalAcceptNavPageStateContainer}
        options={{gestureEnabled: false}}
      />
      <StackNav.Screen
        name="LoanTilaDisclosure"
        component={LoanTilaDisclosureNavPageStateContainer}
      />
      <StackNav.Screen
        name="CollectDebitCardNumbersForLoansProduct"
        component={CollectDebitCardNumbersForLoanNavPageStateContainer as unknown as React.FC}
      />
      <StackNav.Screen name="HowPaymentsWork" component={HowPaymentsWorkNavPageStateContainer} />
      <StackNav.Screen
        name="PaymentReviewTilaAndLoanAgreementCA"
        component={PaymentReviewTilaAndLoanAgreementCANavPageStateContainer}
      />
      <StackNav.Screen
        name="MoneyOnTheWay"
        component={MoneyOnTheWayNavigationContainer}
        options={{headerShown: false, gestureEnabled: false}}
      />
      <StackNav.Screen name="ReasonSurvey" component={ReasonSurveyNavPageStateContainer} />
      <StackNav.Screen name="LoanHistory" component={LoanHistory} />
      <StackNav.Screen name="DocumentsHistory" component={DocumentsHistory} />
      <StackNav.Screen name="CardsHistory" component={CardsHistory} />
      <StackNav.Screen name="RetakeFrontDL" component={RetakeFrontDL} />
      <StackNav.Screen name="RetakeLiveness" component={RetakeLiveness} />
      <StackNav.Screen name="ProofOfResidence" component={ProofOfResidence} />
      <StackNav.Screen name="ProofOfResidenceConfirm" component={ProofOfResidenceConfirm} />
      <StackNav.Screen name="SetSchedule" component={SetSchedule} />
      <StackNav.Screen name="PDFViewer" component={PDFViewer} />
      <StackNav.Screen name="GenericNonModalTemplate" component={GenericNonModalTemplate} />
      <StackNav.Screen name="PaymentRescheduleCalendar" component={PaymentRescheduleCalendar} />
      <StackNav.Screen
        name="AggregatorYodlee"
        component={AggregatorYodlee as unknown as React.FC}
      />
      <StackNav.Screen
        name="ReapplicationBank"
        component={ReapplicationBankNavPageStateContainer}
      />
      <StackNav.Screen
        name="UpgradeToInstallment"
        component={UpgradeToInstallmentPlanNavPageStateContainer as unknown as React.FC}
        options={{headerShown: false, gestureEnabled: false}}
      />
      <StackNav.Screen
        name="DisbursementMethodSelection"
        component={LoanDisbursementMethodSelectionNavPageStateContainer as unknown as React.FC}
      />
      <StackNav.Screen name="ConfirmDebitCard" component={ConfirmDebitCardNavPageStateContainer} />
      <StackNav.Screen name="PhoneConfirmationApproval" component={PhoneConfirmationApproval} />
      <StackNav.Screen name="NameMatchesIDConfirm" component={NameMatchesIDConfirm} />
      <StackNav.Screen name="DuplicatePhoneNumber" component={DuplicatePhoneNumber} />
      <StackNav.Screen
        name="AddBankLink"
        component={AddBankLink}
        options={
          shouldShowAddBankLinkPageWithBanner()
            ? {...(headerButtonsWhiteColor as StackNavigationOptions)}
            : undefined
        }
      />
      <StackNav.Screen
        name="UnsupportedBanks"
        component={UnsupportedBanks as unknown as React.FC}
      />
      <StackNav.Screen
        name="ScreenSelectorModuleContainer"
        component={ScreenSelectorModuleContainer as unknown as React.FC}
      />
      <StackNav.Screen name="OutOfState" component={OutOfState} />
      <StackNav.Screen name="Profile" component={Profile} />
      <StackNav.Screen name="EmailVerification" component={EmailVerificationRouteContainer} />
      <StackNav.Screen name="AccountManagementV2" component={AccountManagementV2} />
      <StackNav.Screen name="AddNewAccountToPossible" component={AddNewAccountToPossible} />
      <StackNav.Screen name="BankAddRoutingAndAccount" component={BankAddRoutingAndAccount} />
      <StackNav.Screen name="BankVerifyRoutingAndAccount" component={BankVerifyRoutingAndAccount} />
      <StackNav.Screen name="AccountsWithPossible" component={AccountsWithPossible} />
      <StackNav.Screen
        name="PrimaryAccountSelection"
        component={PrimaryAccountSelectionRouteContainer}
      />
      <StackNav.Screen name="AddPrimaryAccount" component={AddPrimaryAccount} />
      <StackNav.Screen name="SetAccountAsPrimary" component={SetAccountAsPrimary} />
      <StackNav.Screen name="SetLoanAutoPayAccount" component={SetLoanAutoPayAccount} />
      <StackNav.Screen
        name="BankAggregatorAccountSelectionForAdhocPayment"
        component={BankAggregatorAccountSelectionForAdhocPayment}
      />
      <StackNav.Screen
        name="BankAggregatorAccountSelectionForPrimaryAccount"
        component={BankAggregatorAccountSelectionForPrimaryAccount}
      />
      <StackNav.Screen name="LeftDrawer" component={LeftDrawer as unknown as React.FC} />
      <StackNav.Screen name="JointAccountVerification" component={JointAccountVerification} />
      <StackNav.Screen name="ProofOfIncome" component={ProofOfIncome} />
      <StackNav.Screen name="StateDisclosure" component={StateDisclosureNavPageStateContainer} />
      <StackNav.Screen
        name="AcceptAgreementsWAInstallment"
        component={AcceptAgreementsWAInstallment as unknown as React.FC}
      />
      <StackNav.Screen
        name="LoanFinalAcceptWAExtendedInstallment"
        component={LoanFinalAcceptNavPageStateContainer}
      />
      <StackNav.Screen name="LoanPay" component={LoanPayNavigator} options={{headerShown: false}} />

      <StackNav.Screen name="CardSubmitApplication" component={CardSubmitApplication} />
      <StackNav.Screen name="CardVerifyBankDetails" component={CardVerifyBankDetails} />
      <StackNav.Screen name="CardTransactions" component={CardTransactions} />
      <StackNav.Screen name="CardActivatePhysicalCard" component={CardActivatePhysicalCard} />
      <StackNav.Screen name="CardActivatingPhysicalCard" component={CardActivatingPhysicalCard} />
      <StackNav.Screen name="CardPhysicalCardActivated" component={CardPhysicalCardActivated} />
      <StackNav.Screen
        name="CardPhysicalCardActivationFailed"
        component={CardPhysicalCardActivationFailed}
      />
      <StackNav.Screen name="CardAgreementViewer" component={CardAgreementViewerMPOContainer} />
      <StackNav.Screen name="CardDashboard" component={CardDashboard} />
      <StackNav.Screen name="CardDetails" component={CardDetails} />
      <StackNav.Screen
        name="CardApproved"
        component={CardApprovedMPOContainer as unknown as React.FC}
      />
      <StackNav.Screen
        name="CardAutopayOverview"
        component={CardAutopayOverviewMPOContainer as unknown as React.FC}
      />
      <StackNav.Screen
        name="CardActivationAutopaySchedule"
        component={CardActivationAutopayScheduleMPOContainer}
      />
      <StackNav.Screen name="CardViewPaymentMethods" component={CardViewPaymentMethods} />

      <StackNav.Screen
        name="CollectDebitCardNumbersForCardProduct"
        component={CollectDebitCardNumbersForCardProduct as unknown as React.FC}
      />
      <StackNav.Screen
        name="CollectDebitCardNumberForAdhocPayment"
        component={CollectDebitCardNumberForAdhocPayment}
      />
      <StackNav.Screen
        name="CardLanding"
        component={CardLanding as unknown as React.FC}
        options={{
          cardStyle: {flex: 1},
          headerShown: false,
        }}
      />
      <StackNav.Screen name="OTPVerification" component={OTPVerification} />
      <StackNav.Screen name="SettlementOnboarding" component={SettlementOnboarding} />
      <StackNav.Screen name="SettlementReview" component={SettlementReview} />
      <StackNav.Screen
        name="CardAdhocPayment"
        component={CardAdhocPayment}
        options={{headerShown: false}}
      />

      <StackNav.Screen name="PayOverTimeConfirmation" component={PayOverTimeConfirmation} />
      <StackNav.Screen name="CardHowPayOverTimeWorks" component={CardHowPayOverTimeWorks} />
      <StackNav.Screen name="CardPaymentDetails" component={CardPaymentDetails} />
      <StackNav.Screen
        name="CardHowPayOverTimeWorksManual"
        component={CardHowPayOverTimeWorksManual}
      />
      <StackNav.Screen name="CardPayOverTimeDetails" component={CardPayOverTimeDetailsContainer} />
      <StackNav.Screen
        name="HowDoesAutopayWork"
        component={HowDoesAutopayWork as unknown as React.FC}
      />
      <StackNav.Screen name="CardReviewAutopay" component={CardReviewAutopay} />
      <StackNav.Screen
        name="CardSignedUpAutopay"
        component={CardSignedUpAutopay}
        options={{headerShown: false}}
      />
      <StackNav.Screen
        name="CardApplicationRejected"
        component={CardApplicationRejectedContainer}
      />
      <StackNav.Screen name="CardApplicationExpired" component={CardApplicationExpired} />
      <StackNav.Screen name="OTPIdentityVerification" component={OTPIdentityVerification} />
      <StackNav.Screen name="Onboarding" component={Onboarding} options={{headerShown: false}} />
      <StackNav.Screen name="UnsupportedStateWaitList" component={UnsupportedStateWaitList} />
      <StackNav.Screen name="CardHowDelinquencyAffectMe" component={CardHowDelinquencyAffectMe} />
      <StackNav.Screen
        name="CardPayOverTimeSchedule"
        component={CardPayOverTimeScheduleContainer}
      />
      <StackNav.Screen name="CardMakePaymentPOT" component={CardMakePaymentPOT} />
      <StackNav.Screen name="PayOverTimeFAQ" component={PayOverTimeFAQ} />
      <StackNav.Screen
        name="SignupWorkflow"
        component={SignupWorkflow}
        options={{headerShown: false}}
      />
      <StackNav.Screen name="ProductHub" component={ProductHub} />
      <StackNav.Screen
        name="OfferApplicationWorkflow"
        component={OfferApplicationWorkflow}
        options={{headerShown: false}}
      />
      <StackNav.Screen
        name="ApplicationActivationWorkflow"
        component={ApplicationActivationWorkflow}
        options={{headerShown: false}}
      />
      <StackNav.Screen
        name="CardRescheduleUpcomingPayment"
        component={CardRescheduleUpcomingPayment}
      />
      <StackNav.Screen name="ZendeskSSOForm" component={ZendeskSSOForm} />
    </StackNav.Navigator>
  )
}

export const AuthenticatedRoutes: React.FC<RoutesProps> = (props) => {
  useExperimentationWrapper(true)
  useEnrollInCardsIfNecessary()

  const dispatch = usePfDispatch()

  const me = usePfSelector(userMeSelector)

  const wasSentToWeb = usePfSelector(sentToWebSelector)

  useURLNavigation()

  useEffect(() => {
    dispatch(GetMeAction()).catch(() => undefined)
  }, [dispatch])

  useEffect(() => {
    if (wasSentToWeb) {
      const handleAppStateChange = (nextAppState: AppStateStatus): void => {
        if (nextAppState === 'active') {
          setTimeout(() => dispatch(onboardingSentToWeb(false)), 1000)
        }
      }

      const listener = AppState.addEventListener('change', handleAppStateChange)
      return () => listener.remove()
    }
  }, [dispatch, wasSentToWeb])

  const workflows = useWorkflows()
  const initialRoute = useWorkflowsInitialRoute(workflows, initialAuthenticatedRoute)

  if (initialRoute.loading) {
    return (
      <Box fill>
        <Loading type="loader0" size="large" />
      </Box>
    )
  }

  Log.log(`\n\ninitialRouteName=\n${initialRoute.name}\n\n`)

  if (!me || wasSentToWeb) {
    return (
      <Box fill>
        <Loading type="loader0" size="large" />
      </Box>
    )
  }

  return (
    <AppInactiveContentHider>
      <UseUra />
      <MainAndModalRoutes {...props} initialRouteName={initialRoute.name} />
    </AppInactiveContentHider>
  )
}
