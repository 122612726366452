import {StackNavigationProp} from '@react-navigation/stack'
import {head} from 'lodash'
import React from 'react'

import {LoanOffer} from '@possible/cassandra/src/types/types.mobile.generated'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import useRedirectToWeb from 'src/nav/useRedirectToWeb'
import {LoanApplicationProcessing} from 'src/products/general/ApplicationProcessing/LoanApplicationProcessing'
import {ApplicationProcessingProps} from 'src/products/general/ApplicationProcessing/types'
import {useLoanApplicationProcessingStatus} from 'src/products/general/ApplicationProcessing/useLoanApplicationProcessingStatus'
import {ProductHubProductsQuery} from 'src/products/general/ProductHub/ProductHub.gqls'
import * as DashboardLoanUtils from 'src/products/loans/Dashboard/DashboardLoan.utils'
import {LoanEligibleToApplyTile} from 'src/products/loans/Dashboard/LoanEligibleToApplyTile/LoanEligibleToApplyTile'
import {LoanProcessingApprovedGQLContainer} from 'src/products/loans/LoanProcessing/LoanProcessingApproved/LoanProcessingApprovedGQLContainer'
import {LoanProcessingRejectedWorkflowContainer} from 'src/products/loans/LoanProcessing/LoanProcessingRejected/LoanProcessingRejectedWorkflowContainer'
import {usePfDispatch} from 'src/store/utils'
import {ProductsQueryEligibleLoanOfferInfo} from 'src/workflows/types'
import {LoanProcessingCounterOfferGQLContainer} from 'src/products/loans/LoanProcessing/LoanProcessingCounterOffer/LoanProcessingCounterOfferGQLContainer'
import {ProductHubTileWrapper} from 'src/products/general/ProductHub/ProductHubTileWrapper'
import {usePromise} from 'src/lib/usePromise/usePromise'
import {logOfferApplicationErrorAndShowException} from 'src/products/general/OfferApplicationWorkflow/OfferApplication.utils'

type Props = ApplicationProcessingProps & {
  loanOfferInfo?: ProductsQueryEligibleLoanOfferInfo
  loanAggregateStatus?: NonNullable<
    ProductHubProductsQuery['me']['loans']['latestActionableLoan']
  >['aggregateStatus']
  navigation: StackNavigationProp<MainStackParamList, 'ProductHub'>
}

export const LoanProductHub: React.FC<Props> = (props) => {
  const {
    loanOfferInfo,
    loanAggregateStatus,
    navigation,
    onUnderwritingComplete: handleOnUnderwritingComplete,
  } = props
  const loanOffer: LoanOffer | undefined = head(loanOfferInfo?.offers)
  const dispatch = usePfDispatch()
  const shouldRedirectLoanApplyAndAcceptToWeb = useRedirectToWeb()
  const {isLoanApproved, isLoanExpired, isLoanRejected, isCounterOfferFlow, hasApplicationExpired} =
    useLoanApplicationProcessingStatus(false)

  const [handleOnReapply, {isLoading: isReapplyLoading}] = usePromise(
    async () => {
      await DashboardLoanUtils.onReapplyForNewLoan({
        navigation,
        dispatch,
        shouldRedirectLoanApplyAndAcceptToWeb: shouldRedirectLoanApplyAndAcceptToWeb,
        reapplyEventArgs: {
          source: 'LoanProductHub',
        },
        loanOffers: loanOfferInfo ? [loanOfferInfo] : [],
      })
    },
    {catch: (e) => logOfferApplicationErrorAndShowException(e, 'Failed to reapply for new loan')},
  )

  // Display loan offer - right now this is only used in the case where a cards user is
  // rejected. The user will be redirect to the Product Hub after being rejected where
  // they should be able to apply for a loan
  if (loanOfferInfo && loanOffer?.id) {
    return (
      <ProductHubTileWrapper tileKey="loan-eligible-to-apply">
        <LoanEligibleToApplyTile
          testID="ProductHub-CardUser-LoanEligibleToApplyTile"
          onApplyForNewLoan={async () => {
            await handleOnReapply()
          }}
          isLoading={isReapplyLoading}
        />
      </ProductHubTileWrapper>
    )
  }

  /** Activation flow logic copied from LoanProcessing, LoanProcessingResult */

  // Application pending
  if (loanAggregateStatus?.__typename === 'DecisioningLoanAggregateStatus') {
    return <LoanApplicationProcessing onUnderwritingComplete={handleOnUnderwritingComplete} />
  }

  // Counter Offer
  if (isCounterOfferFlow) {
    return (
      <LoanProcessingCounterOfferGQLContainer
        onContinue={(): void => {
          navigation.reset({
            index: 0,
            routes: [{name: 'ApplicationActivationWorkflow'}],
          })
        }}
        onCancel={(): void => {
          navigation.reset({index: 0, routes: [{name: 'Dashboard'}]})
        }}
      />
    )
  }

  if (isLoanExpired || hasApplicationExpired) {
    navigation.reset({index: 0, routes: [{name: 'DashboardLoader'}]})
    return null
  }

  if (isLoanApproved) {
    return (
      <LoanProcessingApprovedGQLContainer
        onComplete={(): void => {
          navigation.reset({
            index: 0,
            routes: [{name: 'ApplicationActivationWorkflow'}],
          })
        }}
      />
    )
  }

  if (isLoanRejected) {
    return <LoanProcessingRejectedWorkflowContainer navigation={navigation} />
  }

  return null
}
