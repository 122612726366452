import {useCassandraLazyQuery, useCassandraMutation} from '@possible/cassandra/src/utils/hooks'
import {useCallback, useEffect, useRef, useState} from 'react'

import {GetPlaidLinkTokenForLayersDocument} from 'src/products/MCU/PlaidLayers/queries/GetPlaidLinkTokenForLayers.gqls'
import {logPlaidLayerError} from 'src/products/MCU/PlaidLayers/PlaidLayers.utils'
import {
  PlaidLinkOnEvent,
  PlaidLinkOnEventMetadata,
  PlaidLinkOnExit,
  PlaidLinkOnSuccess,
  PlaidLinkOptions,
  PlaidLinkStableEvent,
  usePlaidLink,
} from 'react-plaid-link'
import {sendAnalyticEvent} from 'src/lib/Analytics/ampli.utils'
import {ampli} from 'src/lib/Analytics/ampli'
import {usePushPermissions} from 'src/lib/usePushPermissions'
import {ProcessPlaidProfileDocument} from 'src/products/MCU/PlaidLayers/mutations/ProcessPlaidProfile.gqls'

import {
  UsePlaidLayerProps,
  UsePlaidLayersReturnType,
} from 'src/products/MCU/PlaidLayers/usePlaidLayers.types'
import {PlaidOAuthRedirectUri} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/AggregatorPlaid/AggregatorPlaid.consts'
import {useIsFeatureFlagEnabled} from 'src/lib/experimentation/useIsFeatureFlagEnabled'

const usePlaidLayers = ({
  onPreReqFulfilled,
  navigation,
}: UsePlaidLayerProps): UsePlaidLayersReturnType => {
  const shouldUsePlaidLayerForWeb = useIsFeatureFlagEnabled('plaid-layer-for-web')

  const [linkToken, setLinkToken] = useState<string | null>(null)

  const [isVerifyingIfUserIsEligible, setIsVerifyingIfUserIsEligible] = useState<boolean>(false)

  const isUserEligibleRef = useRef<null | ((value: boolean | PromiseLike<boolean>) => void)>(null)

  const [hasPushPermissions] = usePushPermissions()

  const [getPlaidLayerToken, {loading: isLoadingLinkToken}] = useCassandraLazyQuery(
    GetPlaidLinkTokenForLayersDocument,
    {
      variables: {
        input: {
          redirectUri: PlaidOAuthRedirectUri,
        },
      },
      onError: (error) => {
        logPlaidLayerError(error, 'Failed to get Plaid Link Token')
      },
      onCompleted: (data) => {
        const {linkToken} = data.getBankPlaidLinkTokenForOneClickOnboarding
        if (linkToken) {
          setLinkToken(linkToken)
        }
      },
    },
  )

  const [processPlaidProfileMutation, {loading: isProcessingPlaidProfile}] = useCassandraMutation(
    ProcessPlaidProfileDocument,
    {
      onError: (error) => {
        logPlaidLayerError(error, `Failed to process Plaid Profile`)
      },
    },
  )

  const onSuccessfullyProcessedProfile = useCallback(async (): Promise<void> => {
    sendAnalyticEvent(ampli.plaidLayerProcessComplete.bind(ampli))
    if (!hasPushPermissions) {
      navigation?.navigate('NotificationsPermissions')
    } else {
      await onPreReqFulfilled()
    }
  }, [hasPushPermissions, navigation, onPreReqFulfilled])

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    async (publicToken) => {
      const profileToken = publicToken

      try {
        const res = await processPlaidProfileMutation({
          variables: {
            input: {
              token: profileToken,
            },
          },
        })

        if (res.errors) {
          void onPreReqFulfilled()
        } else {
          void onSuccessfullyProcessedProfile()
        }
      } catch (e) {
        void onPreReqFulfilled()
      }
    },
    [onPreReqFulfilled, onSuccessfullyProcessedProfile, processPlaidProfileMutation],
  )

  const onEvent = useCallback<PlaidLinkOnEvent>(
    (eventName: PlaidLinkStableEvent | string, metadata: PlaidLinkOnEventMetadata) => {
      switch (eventName) {
        case PlaidLinkStableEvent.OPEN:
          sendAnalyticEvent(ampli.plaidLayerStart.bind(ampli))
          break
        case PlaidLinkStableEvent.ERROR:
          // any error during Plaid Layers will be logged here
          sendAnalyticEvent(ampli.plaidLayerError.bind(ampli))
          logPlaidLayerError(
            `${metadata.error_message} - linkSessionId: ${metadata.link_session_id}`,
            'Plaid Layers Component',
          )
          break
        case 'SUBMIT_PHONE':
          sendAnalyticEvent(ampli.plaidLayerSubmitPhone.bind(ampli))
          break
        case 'SKIP_SUBMIT_PHONE':
          sendAnalyticEvent(ampli.plaidLayerSkipSubmitPhone.bind(ampli))
          break
        case 'SUBMIT_CREDENTIALS':
          sendAnalyticEvent(ampli.plaidLayerSubmitCredentials.bind(ampli))
          break
        case 'SUBMIT_MFA':
          sendAnalyticEvent(ampli.plaidLayerSubmitMfa.bind(ampli))
          break
        case PlaidLinkStableEvent.EXIT:
          sendAnalyticEvent(ampli.plaidLayerExit.bind(ampli))
          break
        case 'LAYER_READY':
          if (isUserEligibleRef.current) {
            isUserEligibleRef.current(true)
            isUserEligibleRef.current = null // reset the ref
          }
          break
        case 'LAYER_NOT_AVAILABLE':
          if (isUserEligibleRef.current) {
            isUserEligibleRef.current(false)
            isUserEligibleRef.current = null // reset the ref
          }
          break
        default:
          // The Other cases ignored in Plaid Layers
          break
      }
    },
    [],
  )

  const onExit = useCallback<PlaidLinkOnExit>(() => {
    navigation?.navigate('PhoneConfirmation', {optedOutOfPlaidLayer: true})
  }, [navigation])

  useEffect(() => {
    void getPlaidLayerToken()
  }, [getPlaidLayerToken])

  const config: PlaidLinkOptions = {
    token: linkToken,
    onSuccess,
    onEvent,
    onExit,
  }

  const {open, ready: isReady, submit} = usePlaidLink(config)

  // eslint-disable-next-line @typescript-eslint/require-await
  const createLayerSession = useCallback(async (): Promise<void | boolean> => {
    await getPlaidLayerToken()
    return isReady
  }, [getPlaidLayerToken, isReady])

  const openPlaidLayers = useCallback((): void => {
    open()
  }, [open])

  const checkIfEligibleForPlaidLayers = useCallback(
    async (phoneNumber: string): Promise<boolean> => {
      let isEligible = false
      try {
        setIsVerifyingIfUserIsEligible(true)

        submit({phone_number: phoneNumber})

        isEligible = await new Promise<boolean>((resolve) => {
          isUserEligibleRef.current = resolve
        })
      } catch (e) {
        logPlaidLayerError(e, 'Failed to check if user is eligible for Plaid Layers')
      } finally {
        setIsVerifyingIfUserIsEligible(false)
      }

      return isEligible
    },
    [submit],
  )

  const restartLayers = useCallback(async (): Promise<void> => {
    setLinkToken(null)
    await getPlaidLayerToken()
  }, [getPlaidLayerToken])

  const isLoadingPlaidData =
    isLoadingLinkToken || isVerifyingIfUserIsEligible || isProcessingPlaidProfile || !isReady

  const returnForPlaidLayer = {
    isLoadingPlaidData,
    createLayerSession,
    checkIfEligibleForPlaidLayers,
    openPlaidLayers,
    restartPlaidLayers: restartLayers,
  }

  // we are using a FF to determine if layers should be used on web or not, if the flag is off we mock all the returns
  // and the user will not see layers and will use our regular onboarding process.
  const returnForRegularOnboarding = {
    isLoadingPlaidData: false,
    createLayerSession: (): Promise<boolean> => Promise.resolve(false),
    checkIfEligibleForPlaidLayers: (): Promise<boolean> => Promise.resolve(false),
    openPlaidLayers: (): void => {},
    restartPlaidLayers: async (): Promise<void> => {},
  }

  return shouldUsePlaidLayerForWeb ? returnForPlaidLayer : returnForRegularOnboarding
}

export {usePlaidLayers}
